import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EmailsService } from '../api/EmailsService';
import { Spinner } from '../common/components/Spinner';

const getBodyContent = (str) => str.match(/<body[^>]*>([\s\S]*?)<\/body>/i)[0];

const EmailViewContent = ({ error, data }) => {
    const { t } = useTranslation();

    if (error) {
        return (
            <Stack alignItems="center">
                <Typography variant="h4" sx={{ mt: 20, alignText: 'center' }}>
                    {t('error.unknown')}
                </Typography>
                <Typography sx={{ mt: 2, alignText: 'center' }}>{error}</Typography>
            </Stack>
        );
    }

    if (!data) {
        return <Spinner sx={{ mt: 20 }} />;
    }

    return <Paper elevation={3} dangerouslySetInnerHTML={{ __html: data }} />;
};

const EmailView = () => {
    const { emailId } = useParams();
    const [emailHtml, setEmailHtml] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        EmailsService.getHtmlById(emailId)
            .then((data) => setEmailHtml(getBodyContent(data.html)))
            .catch((err) => {
                setError(err.message || '');
            });
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '32px 16px 48px',
            }}
        >
            <EmailViewContent error={error} data={emailHtml} />
        </Box>
    );
};

export { EmailView };
