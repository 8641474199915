import { simpleRequest } from './request';

const EmailsService = {
    getHtmlById: (id) =>
        simpleRequest({
            method: 'get',
            url: `/email-log/${id}/html`,
        }),
};

export { EmailsService };
