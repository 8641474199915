import React from 'react';
import {
    AccessTimeOutlined,
    CheckCircleOutline,
    DoorFrontOutlined,
    EnergySavingsLeafOutlined,
    HandshakeOutlined,
    LocalCafeOutlined,
    NewspaperOutlined,
    SellOutlined,
    StarOutline,
    ThumbUpAltOutlined,
    UpdateOutlined,
} from '@mui/icons-material';
import { SERVICE_TYPE_ID } from '../../models/service-type';

// POSITIONS
const TOP = 'TOP';
const MAIN = 'MAIN';
const BOTTOM = 'BOTTOM';

const firstClassService = {
    color: 'primary',
    backgroundColor: '#E8EBF3',
    label: 'booking.categoryItems.firstClassService',
    icon: <StarOutline />,
};
const premiumService = {
    color: 'primary',
    backgroundColor: '#E8EBF3',
    label: 'booking.categoryItems.premiumService',
    icon: <ThumbUpAltOutlined />,
};
const bestPrice = {
    color: 'primary',
    backgroundColor: '#E8EBF3',
    label: 'booking.categoryItems.bestPrice',
    icon: <SellOutlined />,
};
const doorPickUp = {
    color: 'warning',
    backgroundColor: '#F3F3F3',
    label: 'booking.categoryItems.doorPickUp',
    icon: <DoorFrontOutlined />,
};
const waitingOnDelay = {
    color: 'warning',
    backgroundColor: '#F3F3F3',
    label: 'booking.categoryItems.waitingOnDelay',
    icon: <UpdateOutlined />,
};
const individualPickupTime = {
    color: 'warning',
    backgroundColor: '#F3F3F3',
    label: 'booking.categoryItems.individualPickupTime',
    icon: <AccessTimeOutlined />,
};
const pickupTimeMatchingYourFlight = {
    color: 'warning',
    backgroundColor: '#F3F3F3',
    label: 'booking.categoryItems.pickupTimeMatchingYourFlight',
    icon: <AccessTimeOutlined />,
};

const meetAndGreet = {
    color: 'warning',
    backgroundColor: '#F3F3F3',
    label: 'booking.categoryItems.meetAndGreet',
    icon: <HandshakeOutlined />,
};

const meetAndGreetOptional = {
    ...meetAndGreet,
    label: 'booking.categoryItems.meetAndGreetOptional',
};

const drinksIncluded = {
    color: 'warning',
    backgroundColor: '#F3F3F3',
    label: 'booking.categoryItems.drinksIncluded',
    icon: <LocalCafeOutlined />,
};
const newspaperIncluded = {
    color: 'warning',
    backgroundColor: '#F3F3F3',
    label: 'booking.categoryItems.newspaperIncluded',
    icon: <NewspaperOutlined />,
};
const freeCancellation = {
    color: 'info',
    backgroundColor: '#2EA3F21A',
    label: 'booking.categoryItems.freeCancellation',
    icon: <CheckCircleOutline />,
};
const ecological = {
    color: 'success',
    backgroundColor: '#28892829',
    label: 'booking.categoryItems.ecological',
    icon: <EnergySavingsLeafOutlined />,
};

const itemIdToChipsMeta = {
    [SERVICE_TYPE_ID.COLLECTIVE]: {
        TOP: [bestPrice, ecological],
        MAIN: [doorPickUp, waitingOnDelay, pickupTimeMatchingYourFlight],
        BOTTOM: [freeCancellation],
    },
    [SERVICE_TYPE_ID.INDIVIDUAL]: {
        TOP: [premiumService],
        MAIN: [doorPickUp, waitingOnDelay, individualPickupTime],
        BOTTOM: [freeCancellation],
    },
    [SERVICE_TYPE_ID.EXCLUSIVE]: {
        TOP: [firstClassService],
        MAIN: [doorPickUp, waitingOnDelay, individualPickupTime, drinksIncluded, newspaperIncluded],
        BOTTOM: [freeCancellation],
    },
    [SERVICE_TYPE_ID.UPON_REQUEST]: {
        TOP: [],
        MAIN: [],
        BOTTOM: [],
    },
};

const mapItemIdToChips = (serviceTypeId, isMeetAndGreetAvailable) => {
    const chips = {
        ...(itemIdToChipsMeta[serviceTypeId] || {
            TOP: [],
            MAIN: [],
            BOTTOM: [],
        }),
    };

    if (isMeetAndGreetAvailable && !chips.MAIN.includes(meetAndGreet)) {
        if (serviceTypeId === SERVICE_TYPE_ID.COLLECTIVE) {
            chips.MAIN = [...chips.MAIN, meetAndGreetOptional];
        } else {
            chips.MAIN = [...chips.MAIN, meetAndGreet];
        }
    }

    return chips;
};

export { mapItemIdToChips, TOP, MAIN, BOTTOM };
