import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { findLang } from '../../../common/models/lang';
import { CarImage } from '../CarImage';
import { currency } from '../../../common/utils/currency';
import { useHistory } from 'react-router-dom';
import { FREE_GATE_SERVICE_TYPES, SERVICE_TYPE_ID } from '../../models/service-type';
import { MAIN, TOP, mapItemIdToChips, BOTTOM } from './mapItemIdToChips';
import { uniqueId } from 'lodash';
import { useMediaQuery } from '@mui/material';
import AlChip from '../../../common/components/AlChip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { TRANSFER } from '../../models/transfer';

const _isMeetAndGreetAvailable = (transfer) =>
    transfer.typeId === TRANSFER.FROM_MUNICH || transfer.typeId === TRANSFER.FROM_NUREMBERG;

const BookingChip = ({ meta }) => {
    const { t } = useTranslation();
    const label = t(meta.label);

    return <AlChip color={meta.color} backgroundColor={meta.backgroundColor} label={label} icon={meta.icon}></AlChip>;
};

const TopSection = ({ imageUrl, priceString }) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between">
                <CarImage url={imageUrl} />
                {(priceString && (
                    <Box>
                        <Typography variant="h5" sx={{ mb: 0.5, textAlign: 'right' }}>
                            {t('transfer-total')}
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: 500 }}>
                            {priceString}
                        </Typography>
                    </Box>
                )) || (
                    <Typography variant="body2" sx={{ textAlign: 'right' }}>
                        {t('booking.priceOnRequest')}
                    </Typography>
                )}
            </Stack>
        </Box>
    );
};

const MainSection = ({ title, chips, serviceTypeId, transfer }) => {
    const [expanded, setExpanded] = useState(false);
    const { t, i18n } = useTranslation();
    const isBiggerThanMediumScreenSize = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const getServiceTypeKey = (id) => {
        switch (id) {
            case SERVICE_TYPE_ID.COLLECTIVE:
                return 'collective';
            case SERVICE_TYPE_ID.INDIVIDUAL:
                return 'individual';
            case SERVICE_TYPE_ID.EXCLUSIVE:
                return 'exclusive';
            case SERVICE_TYPE_ID.UPON_REQUEST:
                return 'uponRequest';
            default:
                return console.error('Invalid service type id');
        }
    };

    const serviceTypeKey = getServiceTypeKey(serviceTypeId);

    // Get all available keys from the translation object
    const getTranslationKeys = (type) => {
        if (!type) return [];
        // Get the translation resource for the current language
        const resources = i18n.getResourceBundle(i18n.language, 'translations');
        const serviceInfo = resources?.booking?.serviceInfo?.[type];

        // Return all keys from the service type's translation object
        return serviceInfo ? Object.keys(serviceInfo) : [];
    };

    // Get translations by calling t() with the full path for each key
    const infoTexts = getTranslationKeys(serviceTypeKey)
        .filter((key) => {
            if (key === 'meetAndGreet' && !_isMeetAndGreetAvailable(transfer)) {
                return false;
            }
            return true;
        })
        .map((key) => t(`booking.serviceInfo.${serviceTypeKey}.${key}`));

    return (
        <Box>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography variant="h5" sx={{ pr: 1 }}>
                    {title}
                </Typography>
                <Stack direction="row">
                    {chips[TOP].map((chip) => (
                        <span style={{ display: 'inline-block', marginRight: '8px' }} key={uniqueId()}>
                            <BookingChip meta={chip} />
                        </span>
                    ))}
                </Stack>
            </Stack>

            <Box sx={{ pt: 2 }}>
                {chips[MAIN].map((chip) => (
                    <span style={{ display: 'inline-block', marginRight: '6px', marginBottom: '8px' }} key={uniqueId()}>
                        <BookingChip meta={chip} />
                    </span>
                ))}
            </Box>

            {isBiggerThanMediumScreenSize && (
                <>
                    <Box
                        onClick={handleExpandClick}
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'bottom',
                            color: 'secondary.main',
                            textDecoration: 'underline',
                            userSelect: 'none',
                            py: 1,
                        }}
                    >
                        <span style={{ display: 'inline-block', marginRight: '6px' }}>
                            {t('booking.serviceInfo.moreInformation')}
                        </span>
                        <ExpandMoreIcon
                            sx={{
                                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.3s',
                            }}
                        />
                    </Box>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Box sx={{ pb: 2 }}>
                            <Box component="ul" sx={{ m: 0, pl: 3 }}>
                                {infoTexts.map((text, index) => (
                                    <Typography key={index} component="li">
                                        {text}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                    </Collapse>
                </>
            )}
        </Box>
    );
};

const BottomSection = ({ chips, onSelect }) => {
    const { t } = useTranslation();
    const isSmallerThanMediumScreenSize = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <Box>
            <Stack
                direction={isSmallerThanMediumScreenSize ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="center"
            >
                <Box
                    sx={{
                        marginBottom: isSmallerThanMediumScreenSize ? '16px' : '0',
                        display: 'flex',
                        justifyContent: 'start',
                        width: '100%',
                    }}
                >
                    {chips[BOTTOM].map((chip) => (
                        <div key={uniqueId()}>
                            <BookingChip meta={chip} />
                        </div>
                    ))}
                </Box>
                <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={onSelect}
                    data-cy="transferCategory"
                    sx={{ width: isSmallerThanMediumScreenSize ? '100%' : '170px' }}
                >
                    {t('select')}
                </Button>
            </Stack>
        </Box>
    );
};

const BookingCategoryItem = ({ item, updateFirstTransfer, url, transfer }) => {
    const { i18n } = useTranslation();
    const history = useHistory();

    const langCode = findLang(i18n.language).code;

    const title = item[`title${langCode}`];
    const price = currency(item.price);
    const serviceTypeId = item.serviceTypeId;
    const chips = mapItemIdToChips(serviceTypeId, _isMeetAndGreetAvailable(transfer));

    const onSelect = () => {
        updateFirstTransfer({ name: 'serviceTypeId', value: item.serviceTypeId });
        updateFirstTransfer({ name: 'serviceType', value: item });

        //known bug: if a customer unselects and navigates to page two, this will come back
        if (FREE_GATE_SERVICE_TYPES.includes(item.serviceTypeId)) {
            updateFirstTransfer({ name: 'extras.GATE', value: 1 });
        }

        history.push(`${url}/details`);
    };

    const handleRenderMainSection = (serviceTypeId, transfer) => {
        if (serviceTypeId === SERVICE_TYPE_ID.UPON_REQUEST) {
            const infoText = item[`description${langCode}`].p;

            return (
                <Box sx={{ mr: { sm: 2 } }}>
                    <Typography variant="h5" sx={{ mb: 1 }}>
                        {title}
                    </Typography>
                    <Typography sx={{ mb: 2 }}>{infoText}</Typography>
                </Box>
            );
        } else return <MainSection title={title} chips={chips} serviceTypeId={serviceTypeId} transfer={transfer} />;
    };

    return (
        <Paper
            sx={{
                pt: 2,
                px: 2.5,
                pb: 2.5,
                mb: 2.5,
            }}
        >
            <TopSection imageUrl={item.descriptionImage} priceString={price} />
            {handleRenderMainSection(serviceTypeId, transfer)}
            <BottomSection chips={chips} onSelect={onSelect} />
        </Paper>
    );
};

export { BookingCategoryItem };
