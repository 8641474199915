import React from 'react';
import { Chip } from '@mui/material';

const AlChip = ({ color, size="small", backgroundColor, label, icon }) => (
    <Chip
        color={color}
        size={size}
        sx={{
            backgroundColor: backgroundColor,
            borderRadius: '4px',
            fontWeight: 700,
            borderWidth: '0px',
        }}
        label={label}
        icon={icon}
        variant="outlined"
    />
);

export default AlChip;
