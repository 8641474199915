import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Spinner = ({ sx }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                ...sx,
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export { Spinner };
