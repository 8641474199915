const fontFamilyAccent = ['Oswald', 'sans-serif'].join(',');
const fontFamilyAdd = ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(',');

const palette = {
    primary: {
        main: '#002f87',
    },
    secondary: {
        main: '#ffb813',
    },
    divider: '#959595',
    info: {
        main: '#16A7D4',
    },
    success: {
        main: '#288928',
    },
    // TODO: extend color palette instead of overriding
    // NOTE: used in AlChip component 
    warning: {
        main: '#666666',
    },
};

const typography = {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h3: {
        fontFamily: fontFamilyAccent,
        fontWeight: 500,
    },
    h4: {
        fontFamily: fontFamilyAccent,
    },
    h5: {
        fontFamily: fontFamilyAccent,
        letterSpacing: '0.25px',
        fontWeight: 400,
    },
    h6: {
        fontFamily: fontFamilyAccent,
        letterSpacing: '0.5px',
        fontWeight: 400,
    },
    subtitle1: {
        fontWeight: 500,
    },
    subtitle2: {
        fontFamily: fontFamilyAdd,
        fontSize: '1rem',
        lineHeight: 1.2,
        fontWeight: 400,
    },
    body2: {
        lineHeight: 1.6,
    },
    button: {
        fontWeight: 600,
        letterSpacing: '0.25px',
    },
};

const components = {
    MuiAccordion: {
        styleOverrides: {
            root: ({ theme }) => ({
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: '4px',
                marginBottom: '1px',
            }),
        },
    },
    MuiAccordionSummary: {
        styleOverrides: {
            root: ({ theme }) => ({
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            }),
            content: {
                alignItems: 'center',
                margin: '10px 0',
            },
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: ({ theme }) => ({
                backgroundColor: theme.palette.background.default,
            }),
        },
    },
    MuiSelect: {
        styleOverrides: {
            root: ({ theme }) => ({
                backgroundColor: theme.palette.background.default,
            }),
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                padding: '0 8px 0 0',
            },
            input: {
                padding: '13px 12px',
            },
        },
    },
    MuiAutocomplete: {
        styleOverrides: {
            inputRoot: {
                paddingTop: '5.5px',
                paddingBottom: '5.5px',
                paddingLeft: '7px',
            },
            listbox: {
                '.MuiAutocomplete-option': {
                    display: 'block',
                },
            },
            option: {
                'div:first-of-type': {
                    fontWeight: 500,
                },
            },
        },
    },
    MuiPickersToolbar: {
        styleOverrides: {
            root: ({ theme }) => ({
                backgroundColor: theme.palette.primary.main,
                // TODO find a better way
                '& .MuiTypography-root': {
                    color: theme.palette.primary.contrastText,
                },
            }),
        },
    },
};

const mixins = {
    box: (theme) => ({
        backgroundColor: theme.palette.grey[200],
        marginBottom: '30px',
        padding: '32px 16px',
        display: 'flex',
        justifyContent: 'center',
    }),
};

export default {
    palette,
    typography,
    components,
    mixins,
};
