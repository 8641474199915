import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import { PackagesService } from '../../../api/PackagesService';
import { PageTitle } from '../../../common/components/PageTitle';
import { WithSidebar } from '../WithSidebar/WithSidebar';
import { BookingSummary } from '../BookingSummary';
import { BookingCategoryItem } from './BookingCategoryItem';
import { Spinner } from '../../../common/components/Spinner';
import { catchCallback } from '../../../api/request';
import { bookingClean } from '../BookingPage/bookingClean';
import { findLang } from '../../../common/models/lang';

const BookingCategoryList = ({
    url,
    data: { booking, firstTransfer, secondTransfer },
    update: { updateFirstTransfer },
    dictionary,
}) => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState(undefined);
    const [error, setError] = useState(undefined);
    const { t, i18n } = useTranslation();

    const loadList = () => {
        const request = PackagesService.getServicePackage({
            ...bookingClean(booking),
            languageId: dictionary?.languages.find(({ name }) => name === findLang(i18n.language).name).id,
        });

        request
            .then((data) => {
                setLoading(false);

                // sort the list of service types by their ID, so collective is at the top
                data.sort((a, b) => {
                    return a.serviceTypeId - b.serviceTypeId;
                });

                setList(data);
            })
            .catch(
                catchCallback((error) => {
                    setLoading(false);
                    setError(error);
                }),
            );

        return () => {
            request.cancel();
        };
    };

    useEffect(loadList, [dictionary]);

    const content = (() => {
        if (loading) {
            return <Spinner />;
        }
        if (error) {
            return <Alert severity="error">{t('error.bookingCategoriesNotLoaded')}</Alert>;
        }
        return (
            (list &&
                list.map((item) => (
                    <BookingCategoryItem
                        key={`${item.carCode}_${item.serviceType}`}
                        item={item}
                        updateFirstTransfer={updateFirstTransfer}
                        url={url}
                        transfer={firstTransfer}
                    />
                ))) ||
            ''
        );
    })();

    const sidebar = <BookingSummary url={url} firstTransfer={firstTransfer} secondTransfer={secondTransfer} />;

    return (
        <>
            <PageTitle>{t('transfer-select')}</PageTitle>
            <Box sx={(theme) => theme.mixins.box(theme)}>
                <WithSidebar content={content} sidebar={sidebar} />
            </Box>
        </>
    );
};

export { BookingCategoryList };
